<template>
  <div>
    <p class="mb-6">Movimientos de inventario</p>
    
    <v-card>
      <v-card-subtitle>Filtros</v-card-subtitle>

      <v-card-text>
        <v-row>
         

          <v-col cols="12" sm="6" md="4">
            <select-puesto
              v-model="filtros.id_puesto"
              :add="false"
              :dense="true"
              :outlined="true"
              :clearable="true"
              :ind_principal="true"      
              :storePuesto="true"
              :filtros="{id_reporte:28}" 
            ></select-puesto>
            <SelectAlmacen
            :disabled="filtros.id_puesto.length > 1"
              class="mt-6"
              :outlined="true"
              :clearable="true"
              :dense="true"
              v-model="filtros.id_almacen"
              :filtro="{ id_puesto: filtros.id_puesto[0] } "
              
              :add="false"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <DosFechasVue @getFechas="getFechas" :fechas="[filtros.desde, filtros.hasta]" />
            <v-select
              class="mt-6"
              item-value="id"
              item-text="text"
              dense
              outlined
              v-model="filtros.receta"
              :items="itemsSelect"
              filled
              label="Recetas"
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <SelectCategoria
              :outlined="true"
              :clearable="true"
              :dense="true"
              v-model="filtros.id_categoria"
              :add="false"
            />
            <SelectSubCatoria
              class="mt-6"
              :outlined="true"
              :clearable="true"
              :dense="true"
              v-model="filtros.id_sub_categoria"
              :padre="filtros.id_categoria"
              :add="false"
            />
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <SelectProductoTipo
              :outlined="true"
              :clearable="true"
              :dense="true"
              v-model="filtros.id_producto_tipo"
              :add="false"
            />
          </v-col>

          <v-col cols="12" sm="6" md="8">
            <v-text-field
              class="mt-0 pt-0"
              outlined
              dense
              label="Nombre/Descripcion/referencia"
              v-model="filtros.nombre"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="12" class="text-right">
         
            <v-btn small :loading="cargando" :disabled="cargando" color="primary" @click="buscarDatos()">
              Buscar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-subtitle>Resultado</v-card-subtitle>
      <v-card-text>
        <TablaSimpleReporte
          @GetVer="GetVerComanda"
          :nombreReporte="nombreReporte"
          :cabeceraIn="cabecera"
          :datosIn="datos"
          :exportar="['EXCEL', 'PDF']"
          :orientacionReporte="'l'"
        ></TablaSimpleReporte>
      </v-card-text>
  
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import TablaSimpleReporte from '@/components/TablaSimpleReporte.vue'
import { onBeforeMount, ref } from '@vue/composition-api'
import SelectPuesto from '@/views/sistema/administracion/archivo/puesto/components/SelectPuestoMulti.vue' //'../../../archivo/puesto/components/SelectPuesto.vue';
import ProductoService from '@/api/servicios/ProductosService'
import moment from 'moment'
import store from '@/store'
import SelectCategoria from '../../maestro/categoria/componentes/Select.vue'
import SelectSubCatoria from '@/views/sistema/inventario/maestro/sub-categoria/componentes/Select.vue'
import SelectAlmacen from '@/views/sistema/inventario/maestro/almacen/componentes/Select.vue'
import SelectProductoTipo from '@/views/sistema/inventario/maestro/producto-tipo/componentes/Select.vue'

import DosFechasVue from '@/components/DosFechas.vue'
// demos

export default {
  props:{
    id_puesto:{
      type:String,
      default:-1
    }
  },
  components: {
    TablaSimpleReporte,
    SelectPuesto,
    SelectCategoria,
    SelectSubCatoria,
    SelectAlmacen,
    SelectProductoTipo,
    DosFechasVue,
  },
  watch: {
    'filtros.id_categoria': function (old, news) {
      if (old != news) {
        this.filtros.id_sub_categoria = null
      }
    },
    'filtros.id_puesto': function (old, news) {
      if (old != news) {
        this.filtros.id_almacen = null
      }
    },
    id_puesto : function (old, news){
      
      if (old != news) {
        this.filtros.id_puesto = old
      }
    }
  },
  setup(props) {
    onBeforeMount(()=>{
      if (props.id_puesto != -1) {
        filtros.value.id_puesto = props.id_puesto
      }
    })
    const nombreReporte = ref(`Movimientos de inventario` )
    const itemsSelect = [
      {
        id: 0,
        text: 'TODOS',
      },
      {
        id: 1,
        text: 'SOLO SIN RECETAS',
      },
      {
        id: 2,
        text: 'SOLO CON RECETAS',
      },
    ]
    const fcondicional = dato => {
      if (dato > 0) {
        return 'success--text'
      } else if (dato < 0) {
        return 'error--text'
      } else {
        return 'secondary--text'
      }
    }
    const cabecera = ref([
      { text: 'Id', value: 'producto.id', format: { small: true } },

      { text: 'Ref', value: 'producto.referencia', format: { small: true }, visible: false },
      { text: 'Producto', value: 'producto.nombre', format: { small: true } },
      { text: 'Tipo', value: 'producto.producto_tipo.descripcion', format: { small: true }, visible: false },
      { text: 'Categoria', value: 'producto.categoria.nombre', format: { small: true }, visible: false },
      { text: 'Sub Categoria', value: 'producto.sub_categoria.nombre', format: { small: true }, visible: false },
      { text: 'Area', value: 'puesto', format: { small: true } },
      { text: 'Almacen', value: 'almacen', format: { small: true } },
      { text: 'Medida', value: 'producto.unidad_medida.descripcion', format: { small: true } },
      { text: 'Inicial', value: 'existencia_inicial', format: { small: true, color: { condicional: fcondicional } } },
      { text: 'Ventas', value: 'movimientos.venta', format: { small: true, color: { condicional: fcondicional } } },
      { text: 'Prod. ventas', value: 'movimientos.produccionventa', format: { small: true, color: { condicional: fcondicional } } },
      { text: 'Compras', value: 'movimientos.compra', format: { small: true, color: { condicional: fcondicional } } },
      { text: 'Ajustes', value: 'movimientos.ajuste', format: { small: true, color: { condicional: fcondicional } } },
      { text: 'Fabricacion', value: 'movimientos.fabricacion', format: { small: true, color: { condicional: fcondicional } } },
      { text: 'Translados', value: 'movimientos.translado', format: { small: true, color: { condicional: fcondicional } } },
      { text: 'Entradas', value: 'movimientos.entradas', format: { small: true, color: { condicional: fcondicional } } },
      { text: 'Salidas', value: 'movimientos.salidas', format: { small: true, color: { condicional: fcondicional } } },
      { text: 'Total', value: 'movimientos.existencia', format: { small: true, color: { condicional: fcondicional } } },

      { text: 'Final', value: 'existencia_final', format: { small: true, color: { condicional: fcondicional } } },  
    ])

    const datos = ref([])
    const filtros = ref({
      id_categoria: null,
      id_sub_categoria: null,
      id_puesto: null,
      id_almacen: null,
      id_producto_tipo: null,
      receta: 0,
      nombre: '',
      desde: moment().format('YYYY-MM-DD'),
      hasta: moment().format('YYYY-MM-DD'),
    })

    const cargando = ref(false)

    const buscarDatos = () => {
      cargando.value = true

      ProductoService.rptMovimientoFechas({...filtros.value, id_puesto : filtros.value.id_puesto.join(",") })
        .then(response => {
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            datos.value = response.data.datos
            nombreReporte.value = `Movimientos de inventario ${datos.value[0].desde} ${datos.value[0].hasta}  ${datos.value[0].puesto}-${datos.value[0].almacen}`

            if (datos.value.length == 0) {
              store.commit('setAlert', {
                message: 'No hubo resultado a la busqueda',
                type: 'info',
              })
            }
          } else {
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'waring',
            })
          }
        })
        .catch(err => {})
        .finally(() => {
          cargando.value = false
        })
    }
    const getFechas = datos => {
      filtros.value.desde = datos.desde
      filtros.value.hasta = datos.hasta
    }

    const validar = () => {
      let validado = true

      if (fechas.value.desde == undefined || fechas.value.desde == '') {
        validado = false
      }

      return validado
    }
    return {
      cabecera,
      datos,
      buscarDatos,
      cargando,
      nombreReporte,
      filtros,
      itemsSelect,
      getFechas,
    }
  },
}
</script>
